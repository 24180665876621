<template>
  <div>
    <ContentHeader :title="$gettext('Create a new filter')"> </ContentHeader>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <FormGroupMultiselect
              required
              :label="$gettext('Data source')"
              :options="dataSources"
              v-model="payload.data_source"
              field="data_source"
              @input="handleInputDataSource"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <FormInput
              v-model="payload.name"
              :label="$gettext('Name')"
              field="name"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <b-button
              :disabled="schema.length === 0"
              variant="default"
              @click="addFilter"
              class="mr-2"
            >
              <i class="fas fa-plus mr-2"></i>
              <translate>Add filter</translate>
            </b-button>
          </div>
        </div>
        <div
          v-for="(filter, index) in filters"
          :key="index"
          class="row d-flex align-items-center"
        >
          <div class="col-md-3">
            <multiselect
              :options="schema"
              label="label"
              track-by="name"
              v-model="filter.column"
              @input="handleColumnInput($event, filter)"
              :show-labels="false"
            ></multiselect>
          </div>
          <div class="col-md-2">
            <multiselect
              label="label"
              track-by="value"
              :disabled="filter.disabledOperator"
              :options="filter.operatorOptions"
              v-model="filter.operator"
              @input="handleOperatorInput($event, filter)"
              :show-labels="false"
            ></multiselect>
          </div>
          <div
            class="col-md-7 d-flex justify-content-between align-items-center"
          >
            <multiselect
              v-if="filter.showMultiselect"
              :disabled="filter.disabledInput"
              :loading="false"
              :options="filter.options"
              label="option_name"
              :internal-search="true"
              v-model="filter.value"
              :multiple="true"
              track-by="option_id"
              @search-change="handleSearchChange($event, filter)"
              :show-labels="false"
              :show-no-options="false"
              :placeholder="
                !filter.disabledInput ? $gettext('Type to Search...') : ''
              "
            >
              <span slot="noResult"><translate>No results</translate></span>
            </multiselect>
            <b-form-input
              v-else-if="filter.showDatePicker"
              type="date"
              v-model="filter.value"
            ></b-form-input>
            <div v-else-if="filter.showDateRangePicker" class="row w-100">
              <div class="col-md-6">
                <b-input-group :prepend="$gettext('from')" class="mb-0">
                  <b-form-input
                    type="date"
                    v-model="filter.value.start"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-6">
                <b-input-group :prepend="$gettext('to')" class="mb-0">
                  <b-form-input
                    type="date"
                    v-model="filter.value.end"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
            <b-button
              variant="danger"
              pill
              @click="handleDeleteFilter(index)"
              class="btn-icon btn-sm ml-3"
            >
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-6">
            <FormGroupCheckbox
              field="is_default"
              v-model="payload.is_default"
              :label="$gettext('Always apply this filter')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <router-link :to="{ name: 'saved-filters' }" class="btn btn-danger"
              ><translate>Cancel</translate></router-link
            >

            <b-button
              :disabled="!canSave"
              variant="primary"
              @click="handleSave"
              class="mr-2"
            >
              <translate>Save</translate>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {
  createFilter,
  filtersToRequest,
  requestFilterToFilters
} from "@/utils/filters";
import FormGroupMultiselect from "@/components/FormGroups/FormGroupMultiselect.vue";
import FormInput from "@/components/FormGroups/FormInput.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormGroupCheckbox from "@/components/FormGroups/FormGroupCheckbox.vue";
import ContentHeader from "@/components/ContentHeader.vue";

export default {
  components: {
    ContentHeader,
    FormGroupCheckbox,
    FormInput,
    FormGroupMultiselect,
    Multiselect
  },
  props: {
    id: {
      required: false,
      type: [String, Number]
    }
  },
  data() {
    return {
      dataSourceToStore: {
        application: "applications",
        candidate: "candidates",
        "offer-application": "offerApplications",
        offer: "offers"
      },
      filters: [],
      schema: [],
      dataSources: [
        { label: "Applications", value: "application" },
        { label: "Candidates", value: "candidate" },
        { label: "Offer Applications", value: "offer-application" },
        { label: "Offers", value: "offer" }
      ],
      payload: {
        data_source: {},
        name: "",
        filters: [],
        is_default: false
      }
    };
  },
  computed: {
    ...mapGetters("savedFilters", ["item"]),
    isUpdate() {
      return this.id;
    },
    filtersIsEmpty() {
      return this.filters.length === 0;
    },
    canSave() {
      return this.filters.length > 0 && this.payload.data_source.value;
    }
  },
  async created() {
    if (this.isUpdate) {
      await this.fetchOne(this.id);
      await this.fetchSchema(this.item.data_source);
      this.filters = requestFilterToFilters(
        this.item.filters || [],
        this.schema
      );
      Object.assign(this.payload, this.item);
      this.payload.data_source = this.dataSources.find(
        ({ value }) => this.payload.data_source === value
      );
      this.payload.is_default = this.item.is_default;
    }
    this.stopFetching();
  },
  methods: {
    ...mapActions("form", ["submit"]),
    ...mapActions("savedFilters", ["create", "update", "fetchOne"]),
    ...mapMutations("app", ["stopFetching"]),
    handleSave() {
      this.isUpdate ? this.handleUpdate() : this.handleCreate();
    },
    handleCreate() {
      this.submit(() => {
        this.payload.filters = this.filters.map(filter => filter.toRequest());
        return this.create(this.payload).then(() => {
          this.clearDefault();
          this.$router.push({ name: "saved-filters" });
        });
      });
    },
    clearDefault() {
      if (this.payload.is_default === false) {
        return this.$store.commit(
          `${
            this.dataSourceToStore[this.payload.data_source.value]
          }/clearFilters`
        );
      }
    },
    handleUpdate() {
      this.submit(() => {
        this.payload.filters = this.filters.map(filter => filter.toRequest());
        return this.update({
          ...this.payload,
          id: this.id
        }).then(() => {
          this.clearDefault();
          this.$router.push({ name: "saved-filters" });
        });
      });
    },
    async handleInputDataSource(value) {
      this.filters = [];
      if (value) {
        await this.fetchSchema(value.value);
        this.addFilter();
      } else {
        this.schema = [];
      }
    },
    fetchSchema(name) {
      return this.$http.get(`ats/schemas/${name}`).then(({ data }) => {
        this.schema = data;
      });
    },
    addFilter() {
      return this.filters.push(createFilter());
    },
    handleColumnInput(column, filter) {
      filter.setColumn(column);
    },
    handleOperatorInput(operator, filter) {
      filter.setOperator(operator);
    },
    handleSearchChange(q, filter) {
      if (filter.column.isEndpointOption) {
        const url = `ats/${filter.column.options.endpoint}?name=${filter.column.name}&q=${q}`;
        this.$http.get(url).then(({ data }) => (filter.options = data));
      }
    },
    filtersToRequest(filters) {
      return filtersToRequest(filters);
    },
    async handleDeleteFilter(index) {
      this.filters.splice(index, 1);
    }
  }
};
</script>
